import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

import RelatedCaseStudies from "components/case-study/RelatedCaseStudies";
import CaseStudyPage from "components/case-study/CaseStudyPage";
import GlobalLayout from "components/page/GlobalLayout";
import TrialBanner from "components/common/footer-banners/TrialBanner";

const CaseStudyTemplate = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
  } = data;

  const { edges: _caseStudies } = data.allMarkdownRemark;

  return (
    <GlobalLayout>
      <Helmet>
        <title>{frontmatter.metaTitle}</title>
        <meta name="description" content={frontmatter.metaDescription} />
        <meta property="og:image" content={frontmatter.metaImage} />
        <script
          src={`https://fast.wistia.com/embed/medias/${frontmatter.videoId}.jsonp`}
          async
        />
        <script src="https://fast.wistia.com/assets/external/E-v1.js" async />
      </Helmet>
      <CaseStudyPage
        companyLogo={frontmatter.companyLogo}
        heroImage={frontmatter.heroImage}
        videoId={frontmatter.videoId}
        companyName={frontmatter.companyName}
        title={frontmatter.pageTitle}
        stats={frontmatter.stats}
        introQuote={frontmatter.introQuote}
        authorName={frontmatter.authorName}
        authorRole={frontmatter.authorRole}
        pageContent={frontmatter.pageContent}
      />
      <RelatedCaseStudies
        relatedIndustry={frontmatter.industry}
        fetchedCaseStudies={_caseStudies}
        currentCaseStudyCompany={frontmatter.companyName}
      />
      <TrialBanner />
    </GlobalLayout>
  );
};

export default CaseStudyTemplate;

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        draft
        metaTitle
        metaDescription
        metaImage
        companyLogo
        heroImage
        videoId
        companyName
        pageTitle
        stats {
          statValue
          statType
        }
        introQuote
        authorName
        authorRole
        pageContent
        industry
      }
    }
    allMarkdownRemark(
      limit: 18
      sort: { fields: [frontmatter___date], order: ASC }
      filter: {
        frontmatter: { templateKey: { eq: "case-study" }, draft: { ne: true } }
      }
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date
            companyLogo
            heroImage
            companyName
            pageTitle
            featured
            industry
            cardLogo
          }
        }
      }
    }
  }
`;
