import TableOfContents from "components/table-of-contents/TableOfContents";
import TableOfContentsMobile from "components/table-of-contents/TableOfContentsMobile";
import { css } from "linaria";
import React from "react";
import { Converter } from "showdown";

import useIsMobile from "../../hooks/useIsMobile";
import { FancyPageTitle } from "../rhombus-UI/theme/typography";
import BackLink from "components/common/BackLink";

const articleStyles = css`
  padding: 5rem 2.5rem;
  display: flex;
  justify-content: center;
  h1 {
    font-size: 42px;
  }
  @media (max-width: 600px) {
    padding: 5rem 1.25rem;
    h1 {
      font-size: 32px;
    }
  }
`;

const sectionInner = css`
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  gap: 10rem;
  @media (max-width: 600px) {
    gap: 2.5rem;
  }
`;

const headerStyles = css`
  display: flex;
  @media (max-width: 1175px) {
    flex-direction: column;
    gap: 2.5rem;
  }
`;

const headerLeft = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 2.5rem;
  gap: 1.25rem;
  width: 45%;
  img {
    width: 120px;
    object-fit: contain;
  }

  @media (max-width: 1175px) {
    width: 100%;
  }
`;

const statRow = css`
  display: flex;
  flex-wrap: wrap;
  gap: calc(100% / 8);
  margin-top: 2.5rem;
  @media (max-width: 600px) {
    gap: 1.5rem;
  }
  .col {
    display: flex;
    flex-direction: column;
    text-align: justify;
    justify-content: center;
    flex-shrink: 0;
    span {
      font-size: 32px;
      font-weight: 900;
    }
    p {
      text-transform: capitalize;
    }
  }
`;

const headerRight = css`
  display: inline-block;
  flex: 1;
  width: 50%;
  height: 100%;
  @media (max-width: 1175px) {
    width: 100%;
  }
`;

const heroImageStyles = css`
  object-fit: cover;
  object-position: center;
  width: 100%;
  max-height: 500px;
  border-radius: 1rem;
  overflow: hidden;
`;

const pageContentWrapper = css`
  display: flex;
  @media (max-width: 1175px) {
    flex-direction: column;
  }
  img {
    max-width: 100%;
  }
`;

const content = css`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 5rem;
  blockquote {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    q {
      line-height: 1.5;
      font-style: italic;
      font-size: 24px;
      font-weight: 300;
    }
    cite {
      display: flex;
      flex-direction: column;
      font-size: 1rem;
    }
  }
  #content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1.25rem;
    img {
      margin: 1.25rem 0;
    }
    h2,
    h3 {
      line-height: 1.5;
    }

    h2 {
      font-size: 32px;
      margin-top: 5rem;
      scroll-margin-top: 12.5rem;
      position: relative;
      @media (max-width: 1175px) {
        font-size: 24px;
        scroll-margin-top: 37.5rem;
      }
      @media (max-width: 600px) {
        scroll-margin-top: 25rem;
      }
      &::before {
        content: "";
        height: 0.25rem;
        width: 5rem;
        background: var(--blue-100);
        position: absolute;
        top: -1.25rem;
      }
    }
    h3 {
      font-size: 18px;
    }
    blockquote {
      p {
        font-style: italic;
      }
    }
  }
`;

const CaseStudyPage = ({
  companyLogo,
  heroImage,
  videoId,
  companyName,
  authorName,
  authorRole,
  title,
  stats,
  introQuote,
  pageContent,
}) => {
  const converter = new Converter();
  const html = converter.makeHtml(pageContent);
  const { isMobile } = useIsMobile(1175);

  const handleVideoOrImage = () => {
    if (!videoId) {
      return <img className={heroImageStyles} src={heroImage} alt="" />;
    }
    return (
      <div
        className="wistia_responsive_padding"
        style={{ padding: "56.25% 0 0 0", position: "relative" }}
      >
        <div
          className="wistia_responsive_wrapper"
          style={{
            height: "100%",
            left: "0",
            position: "absolute",
            top: "0",
            bottom: "0",
            margin: "auto",
            width: "100%",
          }}
        >
          <span
            className={`wistia_embed wistia_async_${videoId} popoverAnimateThumbnail=true videoFoam=true`}
            style={{
              display: "inline-block",
              height: "100%",
              position: "relative",
              width: "100%",
              border: "1px solid #eee",
              boxShadow: "1px 1px 10px #00000020",
            }}
          ></span>
        </div>
      </div>
    );
  };

  return (
    <article className={articleStyles}>
      <section className={sectionInner}>
        <header className={headerStyles}>
          <div className={headerLeft}>
            <BackLink
              path="/case-studies#case-study-feed"
              text="Browse Case Studies"
            />
            <img src={companyLogo} alt="" />
            <FancyPageTitle>{title}</FancyPageTitle>
            <div className={statRow}>
              {stats &&
                stats.map(stat => (
                  <div className="col">
                    <span>{stat.statValue}</span>
                    <p>{stat.statType}</p>
                  </div>
                ))}
            </div>
          </div>
          <div className={headerRight}>{handleVideoOrImage()}</div>
        </header>
        <div className={pageContentWrapper}>
          {!isMobile ? (
            <TableOfContents contentID="content" />
          ) : (
            <TableOfContentsMobile contentID="content" />
          )}
          <div className={content}>
            <blockquote>
              <q>{introQuote}</q>
              <cite>
                <span>
                  <b>{authorName}</b>
                </span>
                <span>
                  {authorRole}, {companyName}
                </span>
              </cite>
            </blockquote>
            <div dangerouslySetInnerHTML={{ __html: html }} id="content" />
          </div>
        </div>
      </section>
    </article>
  );
};

export default CaseStudyPage;
